import script from "./App.vue?vue&type=script&setup=true&lang=js"
export * from "./App.vue?vue&type=script&setup=true&lang=js"

import "./App.vue?vue&type=style&index=0&id=265fb556&lang=scss"
/* custom blocks */
import block0 from "./App.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(script)


const __exports__ = script;

export default __exports__